<template>
  <div class="card">
    <div class="header">
      <a-icon type="arrow-left" class="icon" @click="back" />
      <span class="title">{{ detail.name }}</span>

      <a-radio-group default-value="A" button-style="solid" @change="changeParam" style="margin-left: 20px;">
        <a-radio-button value="A">
          关键参数
        </a-radio-button>
        <a-radio-button value="B">
          全部参数
        </a-radio-button>
      </a-radio-group>
    </div>

    <!-- <a-tabs
          v-model="active"
          :tabBarStyle="{
            margin: '0',
          }"
        >
          <a-tab-pane :key="'1'" tab="公共参数"> </a-tab-pane>
  
          <a-tab-pane
            v-for="item in paramTypeList"
            :key="item.value"
            :tab="item.name"
          >
          </a-tab-pane>
        </a-tabs> -->

    <a-table
      :scroll="{ y: 550 }"
      bordered
      :dataSource="filteredList"
      :pagination="{
        total: filteredList.length,
        pageSize: 10,
        showTotal: (total) => `共 ${total} 条记录`,
      }"
    >
      <a-table-column title="序号" width="80px" align="center">
        <template slot-scope="text, row, index">
          {{ index + 1 }}
        </template>
      </a-table-column>

      <a-table-column title="中文名" data-index="name" />
      <a-table-column title="设计值" width="300px">
        <template slot-scope="text">
          <a-tooltip>
            <template slot="title">
              {{ text.designValue }}
            </template>
            <div style="overflow: hidden;-webkit-line-clamp: 2;text-overflow: ellipsis;display: -webkit-box;-webkit-box-orient: vertical;">{{ text.designValue }}</div>
          </a-tooltip>
        </template>
      </a-table-column>
      <a-table-column title="类型" width="120px" align="center">
        <template slot-scope="text">
          <DataDictFinder
            dictType="bim_value_type"
            :dictValue="String(text.valueType)"
          />
        </template>
      </a-table-column>
      <a-table-column
        title="单位"
        data-index="unit"
        width="120px"
        align="center"
      />
      <a-table-column title="参考信息" data-index="remark" width="240px" />
    </a-table>
  </div>
</template>
  
  <script>
import { fetchDetail } from "@/api/task";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      active: "",
      id: null,
      detail: {},
      list: [],
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    paramTypeList() {
      return this.findDataDict("bim_param_type");
    },

    filteredList() {
      if (this.active) {
        return this.list.filter((item) => item.paramType === this.active);
      } else {
        return this.list;
      }
    },
  },

  mounted() {
    // this.active = this.paramTypeList[0].value;

    const { query } = this.$route;
    const { id } = query || {};
    this.id = id;
    this.getDetail();
  },

  methods: {
    back() {
      this.$router.go(-1);
    },
    getDetail() {
      fetchDetail({ id: this.id }).then((res) => {
        this.detail = Object.freeze(res);
        if (res.body) {
          const arr = JSON.parse(res.body);
          if (Array.isArray(arr)) {
            this.list = arr.filter(v=>v.displayLevel === 'A');
          }
        }
      });
    },
    getDetailAll() {
      fetchDetail({ id: this.id }).then((res) => {
        this.detail = Object.freeze(res);
        if (res.body) {
          const arr = JSON.parse(res.body);
          if (Array.isArray(arr)) {
            this.list = arr;
          }
        }
      });
    },
    changeParam(e) {
      if(e.target.value == "A") {
        this.getDetail();
      }else{
        this.getDetailAll();
      }
    }
  },
};
</script>
  
  <style lang="less" scoped>
.card {
  background: #fff;
  box-shadow: 0px 6px 26px 0px rgba(36, 80, 154, 0.15);
  border-radius: 20px;
  padding: 40px;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .icon {
      font-size: 16px;
      font-weight: bold;
      margin-right: 4px;
    }
    .title {
      font-weight: bold;
      font-size: 16px;
    }
  }
}
</style>
  